import React, { useContext, useState } from "react";
import parse from "html-react-parser";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Container from "../components/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import MoreButton from "../components/MoreButton";
import PageDescription from "../components/PageDescription";
import FeaturedPostSlider from "../components/FeaturedPostSlider";
import MultiPostSliderItem from "../components/MultiPostSliderItem";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";

import i18nContext from "../i18n-context";
import useWpRestApi from "../hooks/useWpRestApi";

const EventPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const classes = useStyles();
  const { palette } = useTheme();
  const i18n = useContext(i18nContext)[lang];
  const [listLength, setListLength] = useState(3);

  const { title, excerpt, translated } = pageContext;
  const sourceUrl = pageContext.featuredImage?.node?.sourceUrl || "";

  const [isEventsAvailable, setEventsAvailable] = useState(
    true
  );
  const events = useWpRestApi(
    "/get-events",
    setEventsAvailable
  );

  // const featuredEvents = data.allWpEvent.nodes.filter(
  //   (node) => node.contentGenericGroup?.isFeatured
  // );
  // const nonFeaturedEvents = data.allWpEvent.nodes.filter(
  //   (node) => !node.contentGenericGroup?.isFeatured
  // );
  const featuredEvents = events.filter(
    (node) => node.contentGenericGroup?.isFeatured
  );
  const nonFeaturedEvents = events.filter(
    (node) => !node.contentGenericGroup?.isFeatured
  );

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>
        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>
      <Banner title={title} bannerUrl={sourceUrl} />
      <Container>
        <PageDescription>{parse(excerpt)}</PageDescription>
        <FeaturedPostSlider
          posts={featuredEvents}
          arrowPos={{
            buttonOffsetY: -213,
            leftButtonOffsetX: -38,
            rightButtonOffsetX: 38,
          }}
        />
        <Grid container spacing={5} className={classes.nonFeaturedSection}>
          {nonFeaturedEvents.slice(0, listLength).map((event) => {
            return (
              <Grid
                item
                key={`non-featured-event-${event.title}`}
                xs={12}
                md={4}
              >
                <MultiPostSliderItem post={event} fullWidth />
              </Grid>
            );
          })}
          <Grid container justifyContent="center">
            {listLength < nonFeaturedEvents.length && (
              <MoreButton
                color={palette.error.main}
                textColor={palette.primary.main}
                onClick={() => setListLength((prevLen) => prevLen + 3)}
              >
                {i18n.showMore}
              </MoreButton>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  nonFeaturedSection: {
    marginBottom: theme.spacing(4),
  },
}));

export const query = graphql`
  query getEventsQuery($locale: String) {
    allWpEvent(
      sort: { fields: eventFieldGroup___eventStartDate, order: DESC }
      filter: { locale: { locale: { eq: $locale } } }
    ) {
      nodes {
        title
        uri
        eventPdfFiles
        eventFieldGroup {
          eventStartDate
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        locationGroup {
          location
        }
        contentGenericGroup {
          isFeatured
          thumbnail {
            sourceUrl
          }
          mobileFeaturedImage {
            sourceUrl
          }
          tabletFeaturedImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

export default EventPage;
